import { useState } from "react"
import styled from "styled-components"

import { subMonths } from "date-fns"

import { EmptyState } from "src/components/EmptyState"
import { ParadiseBarChart } from "src/components/Paradise/ParadiseCharts/ParadiseBarChart"
import { ParadiseLineChart } from "src/components/Paradise/ParadiseCharts/ParadiseLineChart"
import { ParadiseQuickLinks } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceReadings/ParadiseQuickInsights/ParadiseQuickLinks"
import { useParadiseDeviceReadingsQuickLinks } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceReadings/ParadiseQuickInsights/useParadiseDeviceReadingsQuickLinks"
import { ParadiseSelectDeviceReading } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceReadings/ParadiseSelectDeviceReading"
import { useParadiseDeviceReadings } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceReadings/useParadiseDeviceReadings"
import { ParadiseLayout } from "src/components/Paradise/sharedStyles"
import { isValidDeviceReadingtype } from "src/data/devices/logic/deviceLogic"
import { DeviceReadingType } from "src/data/devices/types/deviceTypes"
import { useFetchParadiseDevice } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { useFlags } from "src/hooks/useFlags"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { ButtonStyleReset } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export const PARADISE_QUICK_INSIGHTS_TYPE_PARAM_KEY = "type"

export function ParadiseQuickInsights({
  deviceId,
  type,
}: {
  deviceId: string
  type?: string
}) {
  const { paradiseGraphs2_0 } = useFlags()

  const { searchParams, setSearchParams } = useSearchParams({
    keys: [
      {
        key: PARADISE_QUICK_INSIGHTS_TYPE_PARAM_KEY,
        type: "string",
      },
    ],
  })

  // Remove this once Graphs 2.0 is fully implemented
  const deviceReadingType = type || searchParams.type

  const { quickLinks, toggleQuickLink, hasQuickLink } =
    useParadiseDeviceReadingsQuickLinks()

  const [searchedDeviceReading, setSearchedDeviceReading] = useState(
    deviceReadingType ?? ""
  )
  const [inputDeviceReading, setInputDeviceReading] = useState(
    deviceReadingType ?? ""
  )

  const [selectedDate, setSelectedDate] = useState({
    startAt: subMonths(new Date(), 1),
    endAt: new Date(),
  })

  const { navigate } = useRouter()

  const fetchParadiseDevice = useFetchParadiseDevice({
    deviceId,
  })
  const device = fetchParadiseDevice.data

  if (fetchParadiseDevice.isLoading || !device) {
    return (
      <ParadiseLayout>
        <MSkeleton width="30ch" />
        <MSkeleton width="30ch" />
        <MSkeleton width="30ch" />
      </ParadiseLayout>
    )
  }

  return (
    <ParadiseLayout>
      <SelectWrapper>
        <DeviceReadingsWrapper>
          <ParadiseQuickLinks
            currentReadingType={deviceReadingType}
            quickLinks={quickLinks}
            onClick={(quickLink) => {
              if (!paradiseGraphs2_0 && quickLink) {
                // This looks vey much like magic and contxt aware. But it will be remooved once Graphs 2.0 is fully implemented
                navigate(
                  Routes.ParadiseDeviceReadings.location({
                    deviceId,
                    type: quickLink,
                  })
                )

                setSearchedDeviceReading(quickLink)
                setInputDeviceReading(quickLink)

                return
              }

              if (quickLink) {
                setSearchParams("type", quickLink)

                setSearchedDeviceReading(quickLink)
                setInputDeviceReading(quickLink)
              }
            }}
          />
          <SelectDeviceReadingWrapper>
            <ParadiseSelectDeviceReading
              device={device}
              selectedDeviceReading={deviceReadingType || ""}
              onChange={(value) => {
                if (!paradiseGraphs2_0 && value) {
                  // This looks vey much like magic and contxt aware. But it will be remooved once Graphs 2.0 is fully implemented
                  navigate(
                    Routes.ParadiseDeviceReadings.location({
                      deviceId,
                      type: value as DeviceReadingType,
                    })
                  )

                  return
                }

                if (value) {
                  setSearchParams("type", value)
                }
              }}
              searchedValue={searchedDeviceReading}
              input={inputDeviceReading}
              onInput={(value) => {
                setInputDeviceReading(value)
                setSearchedDeviceReading(value)
              }}
            />
            <QuickLinkButton
              onClick={() => {
                if (
                  deviceReadingType &&
                  isValidDeviceReadingtype(deviceReadingType)
                ) {
                  toggleQuickLink(deviceReadingType)
                }
              }}
              disabled={
                !deviceReadingType ||
                !isValidDeviceReadingtype(deviceReadingType)
              }
            >
              <MText variant="subtitleS" color="unset">
                {!!deviceReadingType &&
                isValidDeviceReadingtype(deviceReadingType) &&
                hasQuickLink(deviceReadingType)
                  ? "Remove from quick links"
                  : "Add to quick links"}
              </MText>
            </QuickLinkButton>
          </SelectDeviceReadingWrapper>
        </DeviceReadingsWrapper>
        <div>
          <DateRangeBox>
            <DateRangePicker
              key={JSON.stringify(selectedDate)}
              startDate={selectedDate.startAt}
              endDate={selectedDate.endAt}
              onDateRangeChange={({ startDate, endDate }) => {
                setSelectedDate({
                  startAt: startDate,
                  endAt: endDate,
                })
              }}
              dateRangeProps={{
                minBookingDays: 2,
              }}
              defaultPresetKey="DAY"
            />
          </DateRangeBox>
        </div>

        {deviceReadingType && isValidDeviceReadingtype(deviceReadingType) ? (
          <ParadiseQuickInsightsData
            type={deviceReadingType}
            dateRange={selectedDate}
            deviceId={deviceId}
          />
        ) : (
          <EmptyState title="Please select reading type" icon={<></>} />
        )}
      </SelectWrapper>
    </ParadiseLayout>
  )
}

function ParadiseQuickInsightsData({
  deviceId,
  type,
  dateRange,
}: {
  deviceId: string
  type: DeviceReadingType
  dateRange: { startAt: Date; endAt: Date }
}) {
  const deviceReadings = useParadiseDeviceReadings({
    deviceId,
    type,
    dateRange,
  })

  if (deviceReadings.isLoading) {
    return (
      <LoadingWrapper>
        <MCircularProgress />
      </LoadingWrapper>
    )
  }

  if (
    type === "motion_events" &&
    hasReadings(deviceReadings.motionReadings?.values)
  ) {
    return (
      <ParadiseBarChart
        data={deviceReadings.motionReadings?.values}
        tooltip={{ unit: "" }}
        canExport
        zoom="xy"
      />
    )
  }

  if (hasReadings(deviceReadings.dashboardReadings)) {
    return (
      <ParadiseLineChart
        data={deviceReadings.dashboardReadings}
        tooltip={{ unit: "" }}
        canExport
        smooth={false}
        zoom="xy"
      />
    )
  }

  if (hasReadings(deviceReadings.readings?.values)) {
    return (
      <ParadiseLineChart
        data={deviceReadings.readings.values}
        tooltip={{ unit: "" }}
        canExport
        smooth={false}
        zoom="xy"
      />
    )
  }

  return <EmptyState icon={<></>} title="No readings available" />
}

const SelectWrapper = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

const DeviceReadingsWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const SelectDeviceReadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(auto, 200px);
  gap: ${spacing.S};
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DateRangeBox = styled.div`
  display: flex;
`

const QuickLinkButton = styled.button`
  ${ButtonStyleReset}
  background-color: ${mColors.neutral};
  border-radius: 8px;
  border: none;
  padding: ${spacing.XS2} ${spacing.S};
  transition: background-color 0.1s;

  &:hover:not(:disabled) {
    background-color: ${mColors.neutralDark};
  }

  &:disabled {
    color: ${mColors.neutralDark3};
    cursor: not-allowed;
  }
`

function hasReadings<Type extends unknown[]>(
  readings: Type | undefined
): readings is Type {
  if (readings && readings.length > 0) {
    return true
  }

  return false
}
