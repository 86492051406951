import styled from "styled-components"

import { DeviceReadingType } from "src/data/devices/types/deviceTypes"
import { ButtonStyleReset } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseQuickLinks({
  quickLinks,
  currentReadingType,
  onClick,
}: {
  quickLinks: DeviceReadingType[]
  currentReadingType?: string | null
  onClick: (quickLink: DeviceReadingType) => void
}) {
  return (
    <Wrapper>
      {quickLinks.map((quickLink) => (
        <QuickLink
          key={quickLink}
          onClick={() => onClick(quickLink)}
          $active={quickLink === currentReadingType}
        >
          <MText variant="subtitleS">{quickLink}</MText>
        </QuickLink>
      ))}
    </Wrapper>
  )
}

const QuickLink = styled.button<{ $active: boolean }>`
  ${ButtonStyleReset}

  background-color: ${({ $active }) =>
    $active ? mColors.neutral : "transparent"};
  border: none;
  border-radius: 4px;
  padding: ${spacing.XS2} ${spacing.XS};

  &:hover {
    background-color: ${mColors.neutral};
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${spacing.S};
`
